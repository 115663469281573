import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/administrar-categorias": [~3],
		"/categorias": [~4],
		"/categorias/[categorySlug]": [~5],
		"/checkout": [~6],
		"/contacto": [7],
		"/cuenta": [~8],
		"/cuenta/direcciones": [~9],
		"/cuenta/pedidos": [~10],
		"/cuenta/pedidos/[orderId]": [~11],
		"/cuenta/tarjetas": [~12],
		"/iniciando-sesion": [13],
		"/iniciar-sesion": [~14],
		"/inventario": [~15],
		"/nosotros": [16],
		"/pedidos": [~17],
		"/pedidos/[orderId]": [~18],
		"/politica-de-devoluciones": [19],
		"/politica-de-privacidad": [20],
		"/productos": [~21],
		"/productos/[productSlug]": [~22],
		"/terminos-y-condiciones": [23]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';